<template>
    <section v-if="dataRender && dataRender.length > 0" class="">
        <div class="container mt-12 lg:mt-22 mb-15">
            <div class="flex flex-col lg:flex-row gap-10 lg:gap-16">
                <div class="flex flex-col w-full lg:w-1/2 p-4 xl:p-6 b-1 b-blacks-10">
                    <h2 class="text-2xl lg:text-xl font-semibold lg:font-medium text-blacks-100">Giỏ hàng của bạn</h2>
                    <div class="flex-1 mt-10 lg:mt-5 h-full flex flex-col">
                        <div class="flex-1 space-y-6">
                            <div v-for="(item, index) in dataRender" :key="item?.id">
                                <div class="flex gap-6">
                                    <nuxt-link
                                        :to="item.url"
                                        class="flex-none aspect-1/1 w-20 lg:w-50 h-20 lg:h-50 b-0.5 b-gray-10"
                                    >
                                        <NuxtImg
                                            :src="getThumbnail(item?.images[0], 400, true)"
                                            width="400"
                                            sizes="sm:100vw md:50vw lg:400px"
                                            loading="lazy"
                                            class="w-full h-full object-cover"
                                        />
                                    </nuxt-link>
                                    <div class="flex flex-col gap-2">
                                        <nuxt-link
                                            :to="item.url"
                                            class="text-blacks-100 text-lg lg:text-xl font-medium hover:underline"
                                            >{{ item?.title }}</nuxt-link
                                        >
                                        <p class="text-sm lg:text-base text-blacks-70">{{ item?.sku }}</p>
                                        <div class="flex items-center gap-4">
                                            <p class="text-xs text-blacks-100">Màu sắc</p>
                                            <div v-if="item?.iconId" class="w-6 h-6 b-0.5 b-gray-400">
                                                <img :src="getThumbnail(item?.iconId, 100, true)" alt="Icon Color" />
                                            </div>
                                            <div
                                                v-else
                                                class="w-6 h-6 b-0.5 b-gray-400 col-flex bg-white box-not-color"
                                            ></div>
                                        </div>

                                        <div v-if="item?.size?.title" class="flex items-center gap-2 lg:gap-4">
                                            <p class="text-xs text-blacks-100">Kích thước</p>
                                            <p class="text-blacks-50 text-base xl:text-lg">{{ item?.size?.title }}</p>
                                        </div>
                                        <div class="flex items-center b-1 b-blacks-30 rounded w-max h-7">
                                            <i
                                                @click="
                                                    dataRender[index].quantity > 1
                                                        ? decreaseItem(item?.uniqueId)
                                                        : false
                                                "
                                                class="i-ic:baseline-minus w-5 h-5 px-4 py-3 text-gray-300 hover:text-blacks-30 cursor-pointer"
                                            ></i>

                                            <input
                                                type="number"
                                                class="w-12 b-x-1 b-blacks-30 text-center h-full"
                                                v-model="dataRender[index].quantity"
                                                @input="changeQuantity(dataRender[index].quantity, item?.uniqueId)"
                                            />
                                            <!-- <p class="w-12 b-x-1 b-blacks-30 h-full col-flex">
                                                {{ dataRender[index].quantity }}
                                            </p> -->
                                            <i
                                                @click="increaseItem(item?.uniqueId)"
                                                class="i-ic:baseline-add w-5 h-5 px-4 py-3 text-gray-300 hover:text-blacks-30 cursor-pointer"
                                            ></i>
                                        </div>
                                        <p
                                            v-if="item?.price && item?.price != 0"
                                            class="text-lg font-medium text-blacks-100 py-4"
                                        >
                                            {{ item?.price }} đ
                                        </p>
                                        <p
                                            @click="handlePopupDelete(item.uniqueId)"
                                            class="underline cursor-pointer mt-auto text-red-600 md:text-black"
                                        >
                                            Xoá
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="totalPrice > 0" class="">
                            <div class="w-full h-1px bg-blacks-10 my-6"></div>
                            <p class="text-[#101828] font-semibold text-lg text-right">
                                Tổng tiền: {{ formattedNumberVi(totalPrice) }} đ
                            </p>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 p-4 xl:p-6">
                    <h2 class="text-xl font-medium text-blacks-100 mb-5">Liên hệ đặt hàng</h2>

                    <form @submit.prevent="sendForm" class="placeholder-addCart space-y-4">
                        <div class="relative">
                            <label
                                for="pay_full_name"
                                :class="errorName ? '!text-error-01' : ''"
                                class="text-sm text-blacks-70 font-medium"
                                >Họ và tên</label
                            >
                            <input
                                type="text"
                                name="full_name"
                                id="pay_full_name"
                                :class="errorName ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                v-model="infoUser.name"
                                @input="infoUser.name = capitalizeFirstLetterOfWords(infoUser.name)"
                                placeholder="Tên"
                            />
                        </div>
                        <div class="relative">
                            <label
                                for="pay_phone"
                                :class="errorPhone ? '!text-error-01' : ''"
                                class="text-sm text-blacks-70 font-medium"
                                >Số điện thoại</label
                            >
                            <input
                                type="text"
                                name="phone"
                                id="pay_phone"
                                :class="
                                    errorPhone ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                "
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                v-model="infoUser.phone"
                                placeholder="(+84) 0000 00000"
                            />
                        </div>
                        <div class="relative">
                            <label
                                for="pay_email"
                                :class="errorEmail ? '!text-error-01' : ''"
                                class="text-sm text-blacks-70 font-medium"
                                >Email</label
                            >
                            <input
                                type="text"
                                name="email"
                                id="pay_email"
                                :class="
                                    errorEmail ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                "
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                v-model="infoUser.email"
                                placeholder="email@company.com"
                            />
                        </div>
                        <div class="">
                            <label class="flex items-start gap-3 checkbox-custom cursor-pointer">
                                <input
                                    type="checkbox"
                                    v-model="infoUser.receive_promotion"
                                    name=""
                                    :class="errorChecked ? '!border-red-500' : ''"
                                    class="select-agree flex-none cursor-pointer"
                                />
                                <p
                                    :class="errorChecked ? 'text-red-500' : ''"
                                    class="text-sm xl:text-base text-blacks-70"
                                >
                                    Đồng ý nhận thông tin khuyến mãi qua email
                                </p>
                            </label>
                        </div>
                        <div class="relative">
                            <label for="pay_memo" class="text-sm text-blacks-70 font-medium">Lời nhắn</label>
                            <textarea
                                name="consult"
                                rows="4"
                                cols="50"
                                id="pay_consult"
                                v-model="infoUser.consult"
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                placeholder="Yêu cầu khác"
                            />
                        </div>
                        <button
                            class="btn-effect-dark w-full b-1 b-blacks-90 text-white bg-blacks-100 text-lg font-medium py-3 xl:py-5 text-center cursor-pointer block"
                        >
                            <p v-if="!loading">Liên hệ đặt hàng</p>
                            <GlobalLoadingSearch v-else class="mx-auto" />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </section>

    <section v-if="dataRender && dataRender.length == 0 && !statusSuccessForm">
        <div class="my-24 container">
            <div class="flex-1 col-flex h-full">
                <i class="i-mdi:cart-arrow-down w-50 h-50 text-blacks-100"></i>
                <p class="text-xl font-semibold text-black mt-5">Chưa có sản phẩm nào trong giỏ hàng</p>
                <nuxt-link
                    to="/bo-suu-tap"
                    class="btn-arrow btn-effect-dark w-max flex items-center justify-center text-white bg-blacks-100 px-12 mt-10 h-15 cursor-pointer"
                >
                    <p>Quay trở lại cửa hàng</p>
                </nuxt-link>
            </div>
        </div>
    </section>

    <div
        v-if="dataRender && dataRender.length == 0 && statusSuccessForm"
        class="container flex flex-col items-center gap-2 my-24"
    >
        <i class="i-ic:baseline-check-circle-outline text-4xl bg-[#01AB56]"></i>
        <p class="text-xl font-bold text-red-500">Đặt hàng thành công</p>
        <p class="text-blacks-50 text-center text-lg">
            Chúng tôi sẽ liên hệ bạn để xác nhận đơn hàng trong thời gian sớm nhất !
        </p>
    </div>
    <Teleport v-if="isOpenPopup" to="body">
        <PopupDelete v-model="isOpenPopup" @deleteItem="handleDeleteItem" />
    </Teleport>

    <SectionRelated
        v-if="dataRelated && dataRelated.length > 0"
        :title="'Gợi ý sản phẩm liên quan'"
        :data="dataRelated"
    />
</template>

<script setup lang="ts">
import nuxtStorage from 'nuxt-storage'
import { useGetShoppingCart } from '../../stores/useShoppingCart'
import PopupDelete from './PopupDeleteItem.vue'
import SectionRelated from '../product/SectionRelated.vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
const globalSettings = inject('globalSettings')
const storeShoppingCart = useGetShoppingCart()
const listCollection = ref([])
const listCategory = ref([])
const dataRender = ref()
const quantityDefault = ref(1)
const isOpenPopup = ref(false)
const { getRelatedProducts, actionPostOrder } = useProducts(globalSettings)
const dataRelated = ref([])
const updateStoreDefault = ref(getStorage('shopping_product') ? getStorage('shopping_product') : [])
const loading = ref(false)
useDirectusCollectionSeo('cart', {})

const changeQuantity = (quantity: number, idUnique: any) => {
    const itemIndex = dataRender.value.findIndex((itemStore: any) => itemStore.uniqueId === idUnique)
    if (itemIndex !== -1 && quantity >= 1) {
        const updatedStore = [...dataRender.value]
        const itemToUpdate = updatedStore[itemIndex]

        itemToUpdate.quantity = quantity

        dataRender.value = updatedStore
        nuxtStorage.localStorage.setData('shopping_product', updateStoreDefault.value, 30, 'd')
        storeShoppingCart.numberProduct = updateStoreDefault.value
    }
    // const updateStored = updateStore.value.filter((itemStore) => itemStore.uniqueId == idUnique)
    // updateStore.value = [...updateStore.value.filter((itemStore) => itemStore.uniqueId !== idUnique), ...updateStored]
}
const formattedNumberCal = (number: any) => {
    if (number) {
        return number.replace(/\./g, '')
    }
    return 0
}
const formattedNumberVi = (number: any) => number.toLocaleString('vi-VN')
const totalPrice = computed(() => {
    if (dataRender.value && dataRender.value.length > 0) {
        return dataRender.value
            .filter((item) => {
                if (!item.priceTerm) {
                    item.priceTerm = Number(formattedNumberCal(item.price))
                }

                const totalPrice = formattedNumberVi(item.priceTerm * item.quantity)
                item.price = totalPrice
                return item
            })
            .reduce((accumulator, currentValue) => {
                if (currentValue.price == 'NaN') {
                    currentValue.price = 0
                }
                if (accumulator == 'NaN') {
                    accumulator = 0
                }

                return accumulator + Number(formattedNumberCal(currentValue.price))
            }, 0)
    }
})
const decreaseItem = (item: any) => {
    const itemIndex = dataRender.value.findIndex((itemStore) => itemStore.uniqueId === item)
    if (itemIndex !== -1) {
        const updatedStore = [...dataRender.value]
        const itemToUpdate = updatedStore[itemIndex]

        itemToUpdate.quantity -= 1

        dataRender.value = updatedStore
    }
    // const updateStored = updateStore.value.filter((itemStore) => itemStore.uniqueId == item)
    // updateStore.value = [...updateStore.value.filter((itemStore) => itemStore.uniqueId !== item), ...updateStored]

    nuxtStorage.localStorage.setData('shopping_product', updateStoreDefault.value, 30, 'd')
    storeShoppingCart.numberProduct = updateStoreDefault.value
}
const increaseItem = (item: any) => {
    const itemIndex = dataRender.value.findIndex((itemStore) => itemStore.uniqueId === item)
    if (itemIndex !== -1) {
        const updatedStore = [...dataRender.value]
        const itemToUpdate = updatedStore[itemIndex]

        itemToUpdate.quantity += 1

        dataRender.value = updatedStore
    }
    // const updateStored = updateStore.value.filter((itemStore) => itemStore.uniqueId == item)
    // const getItem = updateStore.value.find((itemStore) => itemStore.uniqueId == item)
    // updateStored.push(getItem)
    // updateStore.value = [...updateStore.value.filter((itemStore) => itemStore.uniqueId !== item), ...updateStored]
    nuxtStorage.localStorage.setData('shopping_product', updateStoreDefault.value, 30, 'd')
    storeShoppingCart.numberProduct = updateStoreDefault.value
}
const deleteItem = (item: any) => {
    updateStoreDefault.value = [...updateStoreDefault.value.filter((itemStore) => itemStore.uniqueId !== item)]
    dataRender.value = [...dataRender.value.filter((itemStore) => itemStore.uniqueId !== item)]
    nuxtStorage.localStorage.setData('shopping_product', updateStoreDefault.value, 30, 'd')
    storeShoppingCart.numberProduct = updateStoreDefault.value
}
const itemDelelted = ref()
const handlePopupDelete = (item: any) => {
    itemDelelted.value = item
    if (!isOpenPopup.value) {
        isOpenPopup.value = true
    }
    // isDeleteItem.value = true
    // deleteItem(item)
}
const handleDeleteItem = () => {
    deleteItem(itemDelelted.value)
    isOpenPopup.value = false
    // deleteItem(itemDelelted.value)
}
const infoUser = ref({
    phone: '',
    name: '',
    email: '',
    consult: '',
    receive_promotion: false
})
const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded()

    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha('login')

    return token
}

const errorPhone = ref(false)
const errorName = ref(false)
const errorEmail = ref(false)
const errorChecked = ref(false)
const convertedPhoneNumber = () => {
    if (infoUser.value.phone !== '' && infoUser.value.phone[0] === '0') {
        var convertedPhoneNumber = infoUser.value.phone?.substring(1)
        infoUser.value.phone = '+84' + convertedPhoneNumber
    }
}
const checkPhone = () => {
    const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
    if (infoUser.value.phone) {
        const convertedPhoneNumber = infoUser.value.phone.replace(/^\+84/, '0')
        if (vnf_regex.test(convertedPhoneNumber)) {
            errorPhone.value = false
        } else {
            errorPhone.value = true
        }
    } else {
        errorPhone.value = true
    }
}

const checkName = () => {
    if (infoUser.value.name) {
        errorName.value = false
    } else {
        errorName.value = true
    }
}

const checkEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (emailRegex.test(infoUser.value.email)) {
        errorEmail.value = false
    } else {
        errorEmail.value = true
    }
}

const checkChecked = () => {
    if (infoUser.value.receive_promotion) {
        errorChecked.value = false
    } else {
        errorChecked.value = true
    }
}

watch(
    () => infoUser.value.receive_promotion,
    () => {
        checkChecked()
    }
)
const statusSuccessForm = ref(false)
const statusFailForm = ref(false)
const sendForm = async (): Promise<void> => {
    checkPhone()
    checkName()
    checkEmail()
    checkChecked()
    if (!errorName.value && !errorPhone.value && !errorEmail.value && !errorChecked.value) {
        statusSuccessForm.value = false
        loading.value = true

        const token = await recaptcha()
        actionPostOrder(
            {
                name: infoUser.value.name,
                email: infoUser.value.email,
                phone: infoUser.value.phone,
                note: infoUser.value.consult,
                totalPrice: totalPrice.value,
                products: dataRender.value,
                receive_promotion: infoUser.value.receive_promotion
            },
            token
        )
            .then((response) => {
                if (response.code == 201) {
                    loading.value = false
                    nuxtStorage.localStorage.clear()
                    dataRender.value = []
                    storeShoppingCart.numberProduct = []
                    statusFailForm.value = false
                    statusSuccessForm.value = true
                    infoUser.value = {
                        phone: '',
                        name: '',
                        email: '',
                        consult: '',
                        receive_promotion: false
                    }
                } else {
                    statusFailForm.value = true
                }
            })

            .catch((error) => {
                statusFailForm.value = true
            })
    }
}
onMounted(async () => {
    storeShoppingCart.numberProduct = updateStoreDefault.value
    if (storeShoppingCart.countData) {
        dataRender.value = storeShoppingCart.countData
    }
    if (dataRender.value.length > 0) {
        listCollection.value = new Set(storeShoppingCart.countData.map((item) => item.idCollection))
        listCategory.value = new Set(storeShoppingCart.countData.map((item) => item.idCategory))

        dataRelated.value = await getRelatedProducts({
            collection_ids: [...listCollection.value],
            category_ids: [...listCollection.value],
            query: { limit: 6 }
        })
    }
})
</script>

<style>
.placeholder-addCart input::placeholder {
    @apply text-blacks-30;
}
input[type='checkbox'].select-agree {
    @apply b-1 b-blacks-70 rounded-5px;
}

.checkbox-custom input[type='checkbox'].select-agree:checked {
    @apply bg-blacks-100 duration-200;
}
.checkbox-custom input[type='checkbox']:before,
.checkbox-custom input[type='checkbox']:after {
    @apply bg-white;
}
</style>
