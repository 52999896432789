<template>
    <div class="fixed inset-0 bg-black/40 z-10000 col-flex">
        <div class="container bg-white w-sm h-50 p-5 flex flex-col">
            <div class="flex items-center gap-4">
                <i class="i-ic:round-warning text-3xl text-gray-500"></i>
                <p class="text-lg font-semibold text-blacks-100">Xoá sản phẩm</p>
            </div>
            <p class="text-blacsk-70 ml-13 text-sm mt-1">Bạn có chắc muốn xoá sản phẩm đã chọn</p>
            <div class="flex items-center gap-2 ml-auto mt-auto">
                <div
                    @click="$emit('deleteItem', true)"
                    class="p-2 b-2 b-gray-500 hover:bg-gray-500 hover:text-white cursor-pointer duration-200"
                >
                    Xác nhận
                </div>
                <div
                    @click="statusDelete = false"
                    class="px-4 col-flex b-2 b-white bg-black hover:bg-black/70 h-12 text-white cursor-pointer duration-200"
                >
                    Huỷ
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const statusDelete = defineModel()
</script>

<style></style>
